<template>
    <div class="home">
        <Nav />
        <div class="center home-box">
          <div style="width: 8vw; height: 8vh; position:fixed;right: 0px;top:50%">
            <img src="~@/assets/img/gh_b8d420bf0cb6_344.jpg" alt="" style="width:100%" />
          </div>

          <div  style="width: 6vw; height: 6vh; position:fixed;left: 20px;top:50%" @click="showvideo">
            <img src="~@/assets/img/leftss.png" alt="" style="width:100%" />
          </div>
            <el-row :gutter="20">
              <el-col :span="5">
                <template>
                  <div >
                    <router-link to="/my/land" >
                      <img src="~@/assets/img/index_navi/4.png" alt="" style="width:100%" /></router-link>
                  </div>
                  <div style="margin-top: 5px">
                    <router-link to="/my/need" ><img src="~@/assets/img/index_navi/1.png" alt="" style="width: 100%"/></router-link>
                  </div>
                  <div  style="margin-top: 5px">
                    <router-link to="/services" ><img src="~@/assets/img/index_navi/3.png" alt="" style="width: 100%"/></router-link>
                  </div>
                  <div   style="margin-top: 5px">
                    <router-link to="/services" > <img src="~@/assets/img/index_navi/2.png" alt="" style="width: 100%" /></router-link>
                  </div>
                </template>
              </el-col>
              <el-col :span="10" style="height: 295px; width: 511px">
                <template >
                  <div style="height:330px;margin-top: 15px">
                    <ul class="list-do-guide">

                      <li >
                        <a  @click="scrollInto('cz')">    <img src="~@/assets/img/index_navi/czxx.png" alt="" style="width: 115px"/></a>
                      </li>

                      <li>
                        <a  @click="searchLand('工业厂房')">
                          <img src="~@/assets/img/index_navi/gycf.png" alt="" style="width: 115px"/></a>
                      </li>

                      <li >
                        <a  @click="searchLand('工业场地')">
                          <img src="~@/assets/img/index_navi/gycd.png" alt="" style="width: 115px"/></a>
                      </li>

                      <li >
                        <a  @click="searchLand('工矿仓储用地')">
                          <img src="~@/assets/img/index_navi/syyf.png" alt="" style="width: 115px"/>
                          </a>
                      </li>

                    </ul>
                    <ul class="list-do-guide" style="margin-top: 10px">
                      <li >
                        <a @click="scrollInto('gonggeixinxi')">

                          <img src="~@/assets/img/index_navi/zrxx.png" alt="" style="width: 115px"/>
                          </a>
                      </li>

                      <li >
                        <a  @click="searchLand('商服用地')">
                          <img src="~@/assets/img/index_navi/gyyd.png" alt="" style="width: 115px"/></a>
                      </li>

                      <li >
                        <a  @click="searchLand('商服用地')">
                          <img src="~@/assets/img/index_navi/syyd.png" alt="" style="width: 115px"/></a>
                      </li>

                      <li>
                        <a  @click="searchLand('综合用地')">
                          <img src="~@/assets/img/index_navi/zhyd.png" alt="" style="width: 115px"/>
                          </a>
                      </li>

                    </ul>

                  </div>
                </template>
                <!--  <LandPanel title="最新动态" :tabs="newsTab" :activeTabName="newsActiveName" more-link="information#news-test">
                    <template v-slot:tab-trade>
                      <ul class="news_list">
                        <li v-for="item in trades" :key="item.id" class="zi_r_l" style="padding: 0 10px">
                          <RouterLink :to="'/news-detail?id=' + item.id">{{
                              item.title
                            }}</RouterLink>
                          <span>
                                      {{ formatDate(item.createTime) }}
                                  </span>
                        </li>
                      </ul>
                    </template>
                    <template v-slot:tab-news>
                          <ul class="news_list">
                              <li v-for="item in news" :key="item.id" class="zi_r_l" style="padding: 0 10px">
                                  <RouterLink :to="'/news-detail?id=' + item.id">{{
                                      item.title
                                  }}</RouterLink>
                                  <span>
                                      {{ formatDate(item.releaseTime) }}
                                  </span>
                              </li>
                          </ul>
                      </template>
                      <template v-slot:tab-policy>
                          <ul class="news_list">
                              <li v-for="item in policys" :key="item.id" class="zi_r_l" style="padding: 0 10px">
                                  <RouterLink :to="'/news-detail?id=' + item.id">{{
                                      item.title
                                  }}</RouterLink>
                                  <span>
                                      {{ formatDate(item.releaseTime) }}
                                  </span>
                              </li>
                          </ul>
                      </template>
                  </LandPanel>-->
              </el-col>

                <el-col :span="8" style="width: 436px;float: right;    height: 295px;">
                    <div class="land-carousel">
                    <el-carousel :interval="5000" arrow="always" height="295px">
                        <el-carousel-item v-for="item in carousels" :key="item.id">
                            <a :href="item.link">
                                <img :src="item.picUrl+'?w=600'" alt="" style="width: 100%; height: 100%" />
                            </a>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                </el-col>

            </el-row>
            <!-- <div class="home-row-1">
                <div class="quick-entry">
                    <h4>快捷入口</h4>
                    <ul style="margin-top: 20px">
                        <li>
                            <img src="~@/assets/img/home/1.png" alt="" />
                            <p>发布地源</p>
                        </li>
                        <li>
                            <img src="~@/assets/img/home/2.png" alt="" />
                            <p>发布需求</p>
                        </li>
                        <li>
                            <img src="~@/assets/img/home/1.png" alt="" />
                            <p>委托交易</p>
                        </li>
                        <li>
                            <img src="~@/assets/img/home/1.png" alt="" />
                            <p>合同服务</p>
                        </li>
                    </ul>
                </div>
            </div> -->


          <div class="pic mb20" style="margin-top: 15px">
            <img src="~@/assets/img/home/5.jpg" alt="" style="width: 100%;height: 85px;" />
          </div>
          <div class="pic mb20"  style="background: white;padding: 10px 15px; border: 1px solid #23447B;display: flex; display: none">
            <div style="    width: 25%;float: left;background: white;">
              <router-link to="/my/land" >
                <img src="~@/assets/img/index_navi/2.jpeg" alt="" style="width:100%" /></router-link>
            </div>
            <div style="    width: 25%;float: left;background: white;">
              <router-link to="/my/need" ><img src="~@/assets/img/index_navi/3.jpeg" alt="" style="width: 100%"/></router-link>
            </div>
            <div style="    width: 25%;float: left;background: white;">
              <router-link to="/services" ><img src="~@/assets/img/index_navi/1.jpeg" alt="" style="width: 100%"/></router-link>
            </div>
            <div style="    width: 25%;float: left;background: white;">
              <router-link to="/services" > <img src="~@/assets/img/index_navi/4.jpeg" alt="" style="width: 100%" /></router-link>
            </div>
<div style="“clear: both "></div>
          </div>

          <div style="margin-left: 152px; position: absolute;color: #666; ">
            <ul class="list-do-guide" >
              <li style="height: auto;width: 90px;cursor: pointer;" @click="Institutional('市本级')">市本级</li>
              <li  style="height: auto;width: 90px;cursor: pointer;" @click="Institutional('旌阳区')">旌阳区</li>
              <li  style="height: auto;width: 90px;cursor: pointer;" @click="Institutional('罗江区')">罗江区</li>
              <li  style="height: auto;width: 90px;cursor: pointer;" @click="Institutional('绵竹市')">绵竹市</li>
              <li  style="height: auto;width: 90px;cursor: pointer;" @click="Institutional('广汉市')">广汉市</li>
              <li  style="height: auto;width: 90px;cursor: pointer;" @click="Institutional('什邡市')">什邡市</li>
              <li  style="height: auto;width: 90px;cursor: pointer;" @click="Institutional('中江县')">中江县</li>
            </ul>
          </div>
          <div class="pic mb20" style="padding: 10px 0px">
            <span class="tabName">
              交易机构
            </span>
          </div>


          <div class="pic mb20" id="gonggeixinxi" style="padding: 10px 0px">
            <span class="tabName">
              供给信息
            </span>

          </div>

            <HomeSupplyCard />
          <div style="padding-bottom: 10px;color: #666;font-size: 14px;position: relative;    top: 50px;
    left: 136px;">(2023年度国有建设用地供地计划)</div>
          <HomeSupplyCardCR  />

          <div  id ="cz" >
            </div>
            <HomeSupplyCardCZ />

          <div class="pic mb20"  id ="xuqiuxinxi" style="padding: 10px 0px">
            <span class="tabName">
              需求信息
            </span>

          </div>
          <HomeNeedCardZR />
          <HomeNeedCard />

          <OperationVideo v-if="voids" ref="operationvideoRef"   />
          <InstitutionalDescription  ref="InstitutionalDescriptionRef"   />


        </div>
        <footer2 />
    </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/nav";
import footer2 from "components/footer.vue";
import CommonService from "../service/CommonService";
import dayjs from "dayjs";
import { RouterLink } from "vue-router";
import HomeSupplyCardCR from "./HomeSupplyCardCR.vue"
import HomeSupplyCard from "./HomeSupplyCard.vue"
import HomeSupplyCardCZ from "./HomeSupplyCardCZ.vue"
import HomeNeedCard from "./HomeNeedCard.vue"
import InstitutionalDescription from "./InstitutionalDescription.vue"
import HomeNeedCardZR from "./HomeNeedCardZR.vue"
import {useNavStore} from "@/stores/nav";
import {useSearchStore} from "@/stores/search";
import OperationVideo from "./my/OperationVideo.vue";

export default {
    name: "Home",
    components: {
        Nav,
      InstitutionalDescription,
        footer2,
        RouterLink,
      OperationVideo,
        HomeSupplyCard,
      HomeSupplyCardCR,
      HomeSupplyCardCZ,
      HomeNeedCardZR,
        HomeNeedCard
    },
    created() {
        this.loadNews();
    },
    data() {
        return {
          voids:false,
          searchStore: useSearchStore(),
          navStore: useNavStore(),
            newsTab:[
              {
                name: "tab-trade",
                label: "交易公告",
              },
                {
                    name: "tab-news",
                    label: "最新资讯",
                },
                {
                    name: "tab-policy",
                    label: "政策法规",
                }
            ],   params: {
            keyword: "",
            land_type: "",
            land_area: "",
            transaction_way: "",

            demand_purpose: "",
          },
            newsActiveName: "tab-trade",
            news: [],
            trades: [],
            policys: [],
            carousels: [],
            recommendLandList: [],
          navList: [
            { title: "首页", url: "/" },
            // { title: "供给用地", url: "/supply" },
            // { title: "需求用地", url: "/demand" },
            // { title: "存量土地", url: "/stock" },
            { title: "交易大厅", url: "/tradingHall" },
            // { title: "数据分析", url: "/landDataHome" },
            { title: "地图找地", url: "/map" },
            { title: "通知公告", url: "/information" },
            { title: "服务中心", url: "/services" },
            // { title: "个人中心", url: "/my/userinfo" },
            // { title: "登录", url: "/login" },
            // { title: "集团概括", url: "/jituangaikuo" },
            // { title: "集团产业", url: "/keji" },
            // { title: "集团新闻", url: "/xinwen" },
            // { title: "招聘详情", url: "/zhaopinxiangqing0_0" },
            // { title: "联系我们", url: "/lianxi" },
            // { title: "招聘信息", url: "/zhaopinxinxi0" },
          ],
        };
    },
    methods: {
      searchLand(val) {
        // console.log(this.params);
        this.params.keyword=val;
        this.searchStore.$patch({
          ...this.params
        });
        const index = this.navList.findIndex(item => item.url == "/tradingHall");
        this.navStore.$patch({
          activeIndex: index
        });
        this.$router.push({path:"/tradingHall"});
      },
  showvideo(){
   this.voids=true;
    this.$refs.operationvideoRef.openDialog('','', (data) => {
      console.log("1111")
      this.voids=false;
      stop();
    });
  },
      Institutional(title){
        this.voids=true;
        this.$refs.InstitutionalDescriptionRef.openDialog(title,'', (data) => {
          console.log("1111")
          this.voids=false;
          stop();
        });
      },
        handerRouter() {
          this.navStore.$patch({
            activeIndex: '4'
          });
          localStorage.setItem('nav_head_index', '4');
        },
      scrollInto(id) {
        document.getElementById(id).scrollIntoView();
      },

        formatDate(date) {
            return dayjs(date).format("YYYY-MM-DD");
        },
        loadNews() {

            CommonService.getNews({type:1,size:6,current:1}).then((res) => {
                if (res.code === 0) {
                    this.news = res.data.records;
                }
            });
          let params_TradingHall= {
            current:1,
            type:6,
            size:6
          }
          CommonService.getNews(params_TradingHall).then((res) => {
            if (res.code === 0) {
              this.trades = res.data.records;
            }
          });


          CommonService.getNews({type:2,size:6,current:1}).then((res) => {
                if (res.code === 0) {
                    this.policys = res.data.records;
                }
            });

            //getCarousel
            CommonService.getCarousel("main_page").then(res=>{
                if (res.code === 0) {
                    this.carousels = res.data.records;
                }
            });

            //推荐土地列表
            CommonService.getRecommendLandList().then(res=>{
                if (res.code === 0) {
                    this.recommendLandList = res.data.records;
                }
            });


            // CommonService.getLandInfo().then(res=>{
            //   console.log(res)
            // })
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep .el-table thead {
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    background-color: #ecf2f2;
    font-family: "微软雅黑", "宋体", Arail, Tabhoma;
}

::v-deep ul.news_list {
    height: 216px;

    li {
        display: flex;
        flex-direction: row;
        height: 36px;
        line-height: 36px;

        a {
            flex: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #333;
        }

        span{
            width: 120px;
            text-align: right;
            color: #999;
            font-family: 'Helvetica Neue';
            font-size: 14px;
        }

        &::before {
            content: "\25AA";
            /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: red;
            /* Change the color */
            font-size: 18px;
            font-weight: bold;
            /* If you want it to be bold */
            display: inline-block;
            /* Needed to add space between the bullet and the text */
            width: 1em;
            /* Also needed for space (tweak if needed) */
            //margin-left: -1em;
        }
    }
}

::v-deep .land-panel > .el-tabs .el-tabs__content{
    padding: 0px 12px 12px 12px;
}

.home-row-1 {
    height: 300px;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: stretch;

    >div{
        flex-grow: 1;
    }

    .land-carousel {
        flex: 1;
    }

    .land-news {
        background: #fff;
        flex: 1;
        padding: 12px;



        .more {
            position: absolute;
            right: 20px;
            top: 18px;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                color: #409eff;
            }
        }
    }

    .quick-entry {
        box-sizing: border-box;
        width: 310px;
        // height: 352px;
        background: #fff;
        padding: 0 15px 10px 15px;
        flex: 0.5;

        h4 {
            text-align: center;
            padding: 5px 0 5px 0;
            border-bottom: 2px solid #0062b8;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 700;
            color: #242424;
        }

        ul li {
            cursor: pointer;
            // width: 100%;
            height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f4f5f6;
            border-radius: 2px;

            &:nth-of-type(n + 2) {
                margin-top: 15px;
            }

            &:hover {
                color: #0983cc;
                background: #edf7ff;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11);
            }

            img {
                width: 41.5px;
                height: 41.5px;
            }

            p {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000;
                margin-left: 15px;
            }
        }
    }
}


.mb20 {
    margin-bottom: 20px !important;
}

.home-box {
    // height: 50px;
    margin-top: 20px;

    .zixun {
        display: flex;

        // margin-bottom: 20px;

        .more {
            position: absolute;
            right: 20px;
            top: 7px;
            cursor: pointer;

            &:hover {
                color: #409eff;
            }
        }
    }

    .box {
        background-color: #fff;
        margin: 0 auto;
        margin-top: 20px;
        padding: 15px;
        border: 1px solid #ebebeb;
        box-shadow: 0 0 10px #ebebeb;

        .head {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #f0f0f0;
            background: #eef1f1;

            h3 {
                width: 160px;
                height: 60px;
                color: #000;
                font-size: 24px;
                text-align: center;
                line-height: 60px;
                position: relative;

                &::after {
                    content: "";
                    width: 8px;
                    height: 50%;
                    background-color: #0062b8;
                    position: absolute;
                    left: 14px;
                    top: 14px;
                }
            }

            span {
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.tabName{
  background-image: linear-gradient(to right, rgb(19, 67, 131), rgb(62, 107, 148), rgb(243, 240, 247));
  color: white;
  padding: 10px 51px 10px 15px;
  width: 200px;
  font-weight: bold;
}

</style>

<style lang="scss" scoped>
::v-deep .el-tabs {
  .el-tabs__content {
    .el-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

::v-deep .list-service-guide {
  list-style: none;

  li {
    height: 38px;
    line-height: 38px;
    display: flex;
    flex-direction: row;

    &::before {
      content: "\25AA";
      color: #999;
      font-weight: normal;
      display: inline-block;
      width: 2em;
      margin-left: -1em;
      text-align: center;
    }
    a{
      flex: 1;
      color: #333;
    }
    span{
      color: #999;
      font-size: 14px;
    }
  }
}

::v-deep .list-do-guide {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 10px;

  li {
    width: 142px;
    height: 125px;
    border-radius: 4px;
    line-height: 38px;
    display: flex;
    flex-direction: row;
    margin-right: 8px;

    a{
      flex: 1;
      color: #333;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #000000;

      .ti{
        width: 44px;
        height: 44px;
        font-size: 44px;
        font-weight: normal;
        display: inline-block;
      }
    }
  }

}
</style>
